import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import '../styles/style.scss';

const PrivacidadCookies = () => {
    return(
        <Layout>
            <SEO 
            title="Política de privacidad y cookies"
            meta={{
                robots: 'noindex, nofollow'
            }}
            >
            </SEO>

            <section className="section">
                <div className="container">
                    <h1 className="title">Política de Privacidad y Cookies</h1>
                    <p>
                        Rara Avis Lab S.L te informa sobre su Política de Privacidad respecto del tratamiento y protección de los datos de carácter personal de los usuarios y clientes que
                         puedan ser recabados por la navegación o contratación de servicios a través del sitio Web <a href="https://raraavislab.es">raraavislab.es</a>.
                    </p>
                    <p className="has-margin-top-15">
                    En este sentido, el Titular garantiza el cumplimiento de la normativa vigente en materia de protección de datos personales, reflejada en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y de Garantía de Derechos Digitales (LOPD GDD). Cumple también con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas (RGPD).
                    </p>
                    <p className="has-margin-top-15">
                        El uso de sitio Web implica la aceptación de esta Política de Privacidad así como las condiciones incluidas en el <a href="https://raraavislab.es/aviso-legal/">Aviso Legal</a>.
                    </p>
                    
                    <div className="columns is-mobile is-centered">
                        <div className="column is-12-touch is-7-desktop">
                            <div className="box has-margin-top-25">
                                <div className="content">
                                    <ul>
                                        <li>Titular RARA AVIS LAB S.L.</li>
                                        <li>C.I.F.: B-99513525</li>
                                        <li>Nombre comercial registrado compañía: Rara Avis Lab SL</li>
                                        <li>Nombre marca registrada y propiedad de RARA AVIS LAB S.L.: Rara Avis Lab</li>
                                        <li>Oficinas: Calle Pablo Picasso 11, local – 50019 ZARAGOZA</li>
                                        <li>Email: info@raraavislab.es</li>
                                        <li>Teléfono: +34 636056166</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 className="title is-3 is-marginless has-margin-top-30">Principios aplicados en el tratamiento de datos</h2>
                    <p>
                        En el tratamiento de tus datos personales, el Titular aplicará los siguientes principios que se ajustan a las exigencias del nuevo reglamento europeo de protección de datos:
                    </p>

                    <div className="content">
                        <ul>
                            <li><b>Principio de licitud, lealtad y transparencia:</b> El Titular siempre requerirá el consentimiento para el tratamiento de tus datos personales que puede ser para uno o varios fines específicos sobre los que te informará previamente con absoluta transparencia.</li>
                            <li><b>Principio de minimización de datos:</b> El Titular te solicitará solo los datos estrictamente necesarios para el fin o los fines que los solicita.</li>
                            <li><b>Principio de limitación del plazo de conservación:</b> Los datos se mantendrán durante el tiempo estrictamente necesario para el fin o los fines del tratamiento.</li>
                            <li><b>Principio de integridad y confidencialidad:</b> Tus datos serán tratados de tal manera que su seguridad, confidencialidad e integridad esté garantizada. Debes saber que el Titular toma las precauciones necesarias para evitar el acceso no autorizado o uso indebido de los datos de sus usuarios por parte de terceros.</li>
                        </ul>       
                    </div>

                    <h2 className="title is-3 is-marginless has-margin-top-30">Obtención de datos personales</h2>
                    <p>
                        Para navegar por <a href="https://raraavislab.es">raraavislab.es</a> no es necesario que facilites ningún dato personal. Los casos en los que sí proporcionas tus datos personales son los siguientes:
                    </p>
                    <div className="content">
                        <ul>
                            <li>Al contactar a través de los formularios de contacto o enviar un correo electrónico.</li>
                        </ul>       
                    </div>

                    <h2 className="title is-3 is-marginless has-margin-top-30">Tus derechos</h2>
                    <p>
                        El Titular te informa que sobre tus datos personales tienes derecho a:
                    </p>
                    <div className="content">
                        <ul>
                            <li>Solicitar el acceso a los datos almacenados.</li>
                            <li>Solicitar una rectificación o la cancelación.</li>
                            <li>Solicitar la limitación de su tratamiento.</li>
                            <li>Oponerte al tratamiento.</li>
                            <li>Solicitar la portabilidad de tus datos.</li>
                        </ul>       
                    </div>
                    <p>
                        El ejercicio de estos derechos es personal y por tanto debe ser ejercido directamente por el interesado, solicitándolo directamente al Titular, lo que significa que cualquier cliente, suscriptor o colaborador que haya facilitado sus datos en algún momento puede dirigirse al Titular y pedir información sobre los datos que tiene almacenados y cómo los ha obtenido, solicitar la rectificación de los mismos, solicitar la portabilidad de sus datos personales, oponerse al tratamiento, limitar su uso o solicitar la cancelación de esos datos en los ficheros del Titular.
                    </p>
                    <p className="has-margin-top-15">
                        Para ejercitar tus derechos de acceso, rectificación, cancelación, portabilidad y oposición tienes que enviar un correo electrónico a <a href="malito:info@raravislab.es">info@raraavislab.es</a> junto con la prueba válida en derecho como una fotocopia del D.N.I. o equivalente.
                    </p>
                    <p className="has-margin-top-15">
                        Tienes derecho a la tutela judicial efectiva y a presentar una reclamación ante la autoridad de control, en este caso, la Agencia Española de Protección de Datos, si consideras que el tratamiento de datos personales que te conciernen infringe el Reglamento.
                    </p>

                    <h2 className="title is-3 is-marginless has-margin-top-30">Finalidad del tratamiento de datos personales</h2>
                    <p>
                        Cuando te conectas al sitio Web para mandar un correo al Titular, te suscribes a su boletín o realizas una contratación, estás facilitando información de carácter personal de la que el responsable es el Titular. Esta información puede incluir datos de carácter personal como pueden ser tu dirección IP, nombre y apellidos, dirección física, dirección de correo electrónico, número de teléfono, y otra información. Al facilitar esta información, das tu consentimiento para que tu información sea recopilada, utilizada, gestionada y almacenada por <a href="https://raraavislab.es">raraavislab.es</a> , sólo como se describe en el Aviso Legal y en la presente Política de Privacidad.
                    </p>
                    <p className="has-margin-top-15">
                        Los datos personales y la finalidad del tratamiento por parte del Titular es diferente según el sistema de captura de información:
                    </p>
                    <div className="content">
                        <ul>
                            <li>
                                <p><b>Formularios de contacto:</b> El Titular solicita datos personales entre los que pueden estar: Nombre y apellidos, dirección de correo electrónico, número de teléfono y dirección de tu sitio Web con la finalidad de responder a tus consultas.</p>
                                <p>Por ejemplo, el Titular utiliza esos datos para dar respuesta a tus mensajes, dudas, quejas, comentarios o inquietudes que puedas tener relativas a la información incluida en el sitio Web, los servicios que se prestan a través del sitio Web, el tratamiento de tus datos personales, cuestiones referentes a los textos legales incluidos en el sitio Web, así como cualquier otra consulta que puedas tener y que no esté sujeta a las condiciones del sitio Web o de la contratación.</p>
                            </li>
                        </ul>       
                    </div>
                    <p className="has-margin-top-15">
                        Existen otras finalidades por las que el Titular trata tus datos personales:
                    </p>
                    <div className="content">
                        <ul>
                            <li>Para garantizar el cumplimiento de las condiciones recogidas en el Aviso Legal y en la ley aplicable. Esto puede incluir el desarrollo de herramientas y algoritmos que ayuden a este sitio Web a garantizar la confidencialidad de los datos personales que recoge.</li>
                            <li>Para apoyar y mejorar los servicios que ofrece este sitio Web.</li>
                            <li>Para analizar la navegación. El Titular recoge otros datos no identificativos que se obtienen mediante el uso de cookies que se descargan en tu ordenador cuando navegas por el sitio Web cuyas caracterísiticas y finalidad están detalladas en la Política de Cookies .</li>
                        </ul>       
                    </div>

                    <h2 className="title is-3 is-marginless has-margin-top-30">Seguridad de los datos personales</h2>
                    <p>
                        Para proteger tus datos personales, el Titular toma todas las precauciones razonables y sigue las mejores prácticas de la industria para evitar su pérdida, mal uso, acceso indebido, divulgación, alteración o destrucción de los mismos.
                    </p>
                    <p className="has-margin-top-15">
                        El sitio Web está alojado en OVH. La seguridad de tus datos está garantizada, ya que toman todas las medidas de seguridad necesarias para ello. Puedes consultar su política de privacidad para tener más información.
                    </p>

                    <h2 className="title is-3 is-marginless has-margin-top-30">Contenido de otros sitios web</h2>
                    <p>
                        Las páginas de este sitio Web pueden incluir contenido incrustado (por ejemplo, vídeos, imágenes, artículos, etc.). El contenido incrustado de otras web se comporta exactamente de la misma manera que si hubieras visitado la otra web.
                    </p>
                    <p className="has-margin-top-15">
                        Estos sitios Web pueden recopilar datos sobre ti, utilizar cookies, incrustar un código de seguimiento adicional de terceros, y supervisar tu interacción usando este código.
                    </p>

                    <h2 className="title is-3 is-marginless has-margin-top-30">Política de Cookies</h2>
                    <p>
                        Para que este sitio Web funcione correctamente necesita utilizar cookies, que es una información que se almacena en tu navegador web.
                    </p>
                    <p className="has-margin-top-15">
                        En la sección Política de Cookies puedes consultar toda la información relativa a la política de recogida, la finalidad y el tratamiento de las cookies.
                    </p>

                    <h2 className="title is-3 is-marginless has-margin-top-30">Legitimación para el tratamiento de datos</h2>
                    <p>
                        La base legal para el tratamiento de tus datos es: el consentimiento.
                    </p>
                    <p className="has-margin-top-15">
                        Para contactar con el Titular, suscribirte a un boletín o realizar comentarios en este sitio Web tienes que aceptar la presente Política de Privacidad.
                    </p>

                    <h2 className="title is-3 is-marginless has-margin-top-30">Categorías de datos personales</h2>
                    <p>
                        Las categorías de datos personales que trata el Titular son:
                    </p>
                    <div className="content">
                        <ul>
                            <li>Datos identificativos.</li>
                        </ul>       
                    </div>

                    <h2 className="title is-3 is-marginless has-margin-top-30">Conservación de datos personales</h2>
                    <p>
                        Los datos personales que proporciones al Titular se conservarán hasta que solicites su supresión.
                    </p>

                    <h2 className="title is-3 is-marginless has-margin-top-30">Destinatarios de datos personales</h2>
                    <div className="content">
                        <ul>
                            <li><b>Google Analytics</b> es un servicio de analítica web prestado por Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos (“Google”). Encontrarás más información en: <a href="https://analytics.google.com">https://analytics.google.com</a>. Google Analytics utiliza “cookies”, que son archivos de texto ubicados en tu ordenador, para ayudar al Titular a analizar el uso que hacen los usuarios del sitio Web. La información que genera la cookie acerca del uso del sitio Web (incluyendo tu dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos.</li>
                        </ul>       
                    </div>
                    <p>
                        También puedes ver una lista de los tipos de cookies que utiliza Google y sus colaboradores y toda la información relativa al uso que hacen de cookies publicitarias.
                    </p>

                    <h2 className="title is-3 is-marginless has-margin-top-30">Navegación Web</h2>
                    <p>
                        Al navegar por <a href="https://raraavislab.es">raraavislab.es</a> se pueden recoger datos no identificativos, que pueden incluir, la dirección IP, geolocalización, un registro de cómo se utilizan los servicios y sitios, hábitos de navegación y otros datos que no pueden ser utilizados para identificarte.
                    </p>
                    <p className="has-margin-top-15">
                        El sitio Web utiliza los siguientes servicios de análisis de terceros:
                    </p>
                    <div className="content">
                        <ul>
                            <li>Google Analytics.</li>
                        </ul>       
                    </div>
                    <p>
                        El Titular utiliza la información obtenida para obtener datos estadísticos, analizar tendencias, administrar el sitio, estudiar patrones de navegación y para recopilar información demográfica.
                    </p>

                    <h2 className="title is-3 is-marginless has-margin-top-30">Exactitud y veracidad de los datos personales</h2>
                    <p>
                        Te comprometes a que los datos facilitados al Titular sean correctos, completos, exactos y vigentes, así como a mantenerlos debidamente actualizados.
                    </p>
                    <p className="has-margin-top-15">
                        Como Usuario del sitio Web eres el único responsable de la veracidad y corrección de los datos que remitas al sitio exonerando a el Titular de cualquier responsabilidad al respecto.
                    </p>

                    <h2 className="title is-3 is-marginless has-margin-top-30">Aceptación y consentimiento</h2>
                    <p>
                        Como Usuario del sitio Web declaras haber sido informado de las condiciones sobre protección de datos de carácter personal, aceptas y consientes el tratamiento de los mismos por parte de el Titular en la forma y para las finalidades indicadas en esta Política de Privacidad.
                    </p>

                    <h2 className="title is-3 is-marginless has-margin-top-30">Revocabilidad</h2>
                    <p>
                        Para ejercitar tus derechos de acceso, rectificación, cancelación, portabilidad y oposición tienes que enviar un correo electrónico a<a href="malito:info@raravislab.es">info@raraavislab.es</a> junto con la prueba válida en derecho como una fotocopia del D.N.I. o equivalente.
                    </p>
                    <p className="has-margin-top-15">
                        El ejercicio de tus derechos no incluye ningún dato que el Titular esté obligado a conservar con fines administrativos, legales o de seguridad.
                    </p>

                    <h2 className="title is-3 is-marginless has-margin-top-30">Cambios en la Política de Privacidad</h2>
                    <p>
                        El Titular se reserva el derecho a modificar la presente Política de Privacidad y Cookies para adaptarla a novedades legislativas o jurisprudenciales, así como a prácticas de la industria.
                    </p>
                    <p className="has-margin-top-15">
                        Estas políticas estarán vigentes hasta que sean modificadas por otras debidamente publicadas.
                    </p>
                </div>
            </section>

            <section className="section" style={{'paddingTop': 0}}>
                <div className="container">
                    <h1 className="title">Política de Cookies</h1>
                    <p>
                        Debido a la entrada en vigor de la referente modificación de la "Ley de Servicios de la Sociedad de la Información" (LSSICE) establecida por el Real Decreto 13/2012, es de obligación obtener el consentimiento expreso del usuario de todas las páginas web que usan Cookies prescindibles, antes de que éste navegue por ellas.
                    </p>

                    <h2 className="title is-3 is-marginless has-margin-top-30">¿Qué son las Cookies?</h2>
                    <p>
                        Las Cookies y otras tecnologías similares tales como local shared objects, flash Cookies o píxeles, son herramientas empleadas por los servidores Web para almacenar y recuperar información acerca de sus visitantes, así como para ofrecer un correcto funcionamiento del sitio. Mediante el uso de estos dispositivos se permite al servidor Web recordar algunos datos concernientes al usuario, como sus preferencias para la visualización de las páginas de ese servidor, nombre y contraseña, productos que más le interesan, etc.
                    </p>

                    <h2 className="title is-3 is-marginless has-margin-top-30">Cookies afectadas por la normativa y Cookies exceptuadas</h2>
                    <p>
                        Según la directiva de la UE, las Cookies que requieren el consentimiento informado por parte del usuario son las Cookies de analítica y las de publicidad y afiliación, quedando exceptuadas las de carácter técnico y las necesarias para el funcionamiento del sitio Web o la prestación de servicios expresamente solicitados por el usuario.
                    </p>

                    <h2 className="title is-3 is-marginless has-margin-top-30">¿Qué tipos de Cookies existen?</h2>
                    <p>
                        Sobre los tipos de Cookies, existen cinco grandes grupos:
                    </p>
                    <div className="content">
                        <ul>
                            <li><b>Cookies analíticas:</b> recogen información del uso que se realiza del sitio Web.</li>
                            <li><b>Cookies sociales:</b> son aquellas necesarias para redes sociales externas.</li>
                            <li><b>Cookies de afiliados:</b> permiten hacer un seguimiento de las visitas procedentes de otras webs, con las que el sitio Web establece un contrato de afiliación (empresas de afiliación).</li>
                            <li><b>Cookies de publicidad y comportamentales:</b> recogen información sobre las preferencias y elecciones personales del usuario (retargeting).</li>
                            <li><b>Cookies técnicas y funcionales:</b> son las estrictamente necesarias para el uso del sitio Web y para la prestación del servicio contratado.</li>
                        </ul>
                    </div>

                    <h2 className="title is-3 is-marginless has-margin-top-30">Cookies que se utilizan en este sitio Web</h2>
                    <div className="content">
                        <ul>
                            <li>
                                <b>Cookies propias:</b>
                                <ul>
                                    <li>Utilizamos un set de cookies propias, para guardar sus preferencias respecto a las cookies generales y de terceros, estas están agrupadas bajo las necesarias para el funcionamiento mínimo de la página.</li>
                                </ul>
                            </li>
                            <li>
                                <b>Cookies de terceros:</b>
                                <ul>
                                <li><b>Vimeo:</b> Utiliza cookies al incrustar el video de presentación, estas cookies no se pueden desactivar ya que las agrupamos bajo las necesarias de la web, puedes comprobar su política de cookies <a href="https://vimeo.com/cookie_policy">aquí</a>.</li>
                            <li><b>Google Analytics:</b> Utilizamos Google Analytics para entender como la gente visita nuestra web y poder mejorarla, estas cookies son opcionales, puedes comprobar su política de cookies <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es">aquí</a>.</li>
                                </ul>
                            </li>
                        </ul>       
                    </div>

                    <h2 className="title is-3 is-marginless has-margin-top-30">¿Cómo puedo configurar las Cookies o deshabilitarlas?</h2>
                    <p>
                        Puede aceptar, bloquear o eliminar las Cookies instaladas en su equipo mediante la configuración de las opciones del navegador. En los siguientes enlaces encontrará instrucciones para habilitar o deshabilitar las Cookies en los navegadores más comunes:
                    </p>

                    <div className="content">
                        <ul>
                            <li><a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we">Firefox</a></li>
                            <li><a href="http://support.apple.com/kb/HT1677?viewlocale=es_ES">Safair</a></li>
                            <li><a href="https://support.google.com/chrome/answer/95647?hl=es">Google Chrome</a></li>
                            <li>Para otros navegadores, consulte la documentación del navegador que tenga instalado.</li>
                        </ul>
                    </div>

                    <p>
                        <b>Advertencia sobre eliminar Cookies</b>
                    </p>
                    <p>
                        Puede eliminar y bloquear las Cookies de este sitio Web, pero parte del sitio no funcionará correctamente.
                    </p>
                </div>
            </section>
        </Layout>
    )
}

export default PrivacidadCookies;